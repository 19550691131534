import React from "react"
import styled from "styled-components"

const Skeleton = styled.div`
  .lds-dual-ring {
    display: inline-block;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: ${(props) => props.loaderSize};
    height: ${(props) => props.loaderSize};
    margin: 5px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const ButtonLoader = ({ loaderSize }) => {
  return (
    <Skeleton loaderSize={loaderSize}>
      <div className="lds-dual-ring"></div>
    </Skeleton>
  )
}
export default ButtonLoader
