import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { getProductStockLevel } from "../../lib/woocommApi"

const DemoStockLabel = ({ product }) => {
  const [isDemoProduct, setIsDemoProduct] = useState(false)
  const [stockQuantityRemaining, setStockQuantityRemaining] = useState(0)

  const queryResponse = useStaticQuery(QUERY)
  const all_demo_stock = queryResponse.allWcProducts.nodes

  useEffect(() => {
    const acf_data = all_demo_stock.filter(
      (item) => item.wordpress_id == product.id
    )
    if (acf_data.length > 0) {
      setIsDemoProduct(acf_data[0].acf.is_demo_stock)
    }
    getProductStockLevel(product.id).then((stockQuantity) => {
      setStockQuantityRemaining(stockQuantity)
    })
  }, [all_demo_stock, product])

  if (isDemoProduct) {
    return (
      <>
        <DemoProductLableContainer>
          <Title>Store Floor Demo Unit</Title>
          <Description>
            Demo units are sold at a massive discount. They are in good
            condition but might have some blemishes due to handling. Shop-soiled
            items are sold as is, and no 100-day Comfort Trial is available.
            Standard factory guarantees and warranties are still valid on each
            product sold.
          </Description>
          <StockRemaining>
            {stockQuantityRemaining > 0 &&
              "Only " + stockQuantityRemaining + " Left!"}
            {stockQuantityRemaining < 1 && "Sold Out"}
          </StockRemaining>
        </DemoProductLableContainer>
        <Space />
      </>
    )
  } else {
    return ""
  }
}

const DemoProductLableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
`

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
`

const Description = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`

const StockRemaining = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ed1c24;
  font-weight: bold;
  font-size: 14px;
`

const Space = styled.div`
  height: 20px;
`

const QUERY = graphql`
  {
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        acf: { is_demo_stock: { eq: true } }
      }
    ) {
      nodes {
        wordpress_id
        acf {
          is_demo_stock
        }
      }
    }
  }
`

export default DemoStockLabel
