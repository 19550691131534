import React, { useEffect } from "react"
import styled from "styled-components"
import StarRatings from "react-star-ratings"
import ReviewPost from "./ReviewPost"
import { ContentStyling } from "../AccordianImplementation"

const Review = ({
  reviewData = {},
  product,
  goToRatingsValue,
  active,
  toggleActive,
  focusOnTitle,
}) => {
  const scrollRef = React.useRef()
  const reviewArray = reviewData.reviews || []
  const reviewArrayFixed = reviewArray.filter((rev) => {
    if (rev.rating != 0) {
      try {
        rev["date"] = rev.date.split(" ")[0]
      } catch {}
      return rev
    } else {
      return null
    }
  })

  useEffect(() => {
    if (goToRatingsValue && goToRatingsValue > 0) {
      // Scroll to Reviews title
      if (focusOnTitle) {
        focusOnTitle()
      }
      // Open accordian if not open
      if (!active) {
        toggleActive(true)
      }
    }
  }, [goToRatingsValue])

  return (
    <ContentStyling ref={scrollRef}>
      <Container>
        <Summary>
          Overall customer rating{" "}
          <Badge>{reviewData.review_average_rating}</Badge>
          <StarRatings
            rating={parseFloat(reviewData.review_average_rating) || 0.0}
            numberOfStars={5}
            name="rating"
            starDimension="25px"
            starRatedColor="#F7A416"
            starEmptyColor="#CFD5DB"
            starHoverColor="#ffba42"
          />
        </Summary>
        {reviewArrayFixed && reviewArrayFixed.length > 0 ? (
          <Heading>See what others have to say...</Heading>
        ) : (
          <Heading>Be the first to leave a review!</Heading>
        )}
        {reviewArrayFixed.map(({ author, content, rating, date }, idx) => (
          <Single key={name + idx}>
            <Top>
              <Meta>
                {/* <img src={img} /> */}
                <Right>
                  {author}
                  <StarRatings
                    rating={parseFloat(rating)}
                    numberOfStars={5}
                    name="rating"
                    starDimension="16px"
                    starSpacing="2px"
                    starRatedColor="#F7A416"
                    starEmptyColor="#CFD5DB"
                    starHoverColor="#ffba42"
                  />
                </Right>
              </Meta>
              <StyledDate>{date}</StyledDate>
            </Top>
            <Bottom>{content}</Bottom>
          </Single>
        ))}
        <ReviewPost product={product} defaultRating={goToRatingsValue} />
      </Container>
    </ContentStyling>
  )
}
const Single = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid #dee3e8;
  }
  padding: 20px 0;
`
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
const StyledDate = styled.div`
  color: #223247;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
`
const Meta = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 200px;
    width: 40px;
    height: 40px;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  color: #223247;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
`
const Bottom = styled.div`
  color: #223247;
  font-family: Roboto;
  font-size: 15px;
`

const Summary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #223247;
  font-size: 20px;
  padding-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    flex-direction: column;
  }
`

const Heading = styled.div`
  border-bottom: 1px solid #dee3e8;
  color: #223247;
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
`

const Badge = styled.div`
  font-size: 35px;
  color: #77848e;
  border-radius: 6px;
  background-color: #dee3e8;
  padding: 0px 15px;
  margin: 0 20px;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin: 20px;
    font-size: 25px;
  }
`
const Container = styled.div`
  margin: 0;
`

export default Review
