import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ZeropayWidget = memo(({ amount = 0 }) => {
  const {
    zeropayImage: {
      childImageSharp: { gatsbyImageData: zeropay },
    },
  } = useStaticQuery(ZEROPAY_WIDGET_QUERY)

  let parsedAmount = parseAmount(amount)
  let installmentAmount = getInstallmentAmount(parsedAmount)
  if (installmentAmount > 0) {
    return (
      <WidgetBox>
        <GatsbyImage
          image={zeropay}
          style={{ maxHeight: "64px", maxWidth: "160px" }}
          imgStyle={{
            maxHeight: "64px",
            maxWidth: "160px",
            objectFit: "contain",
          }}
          placeholder="none"
          objectFit="contain"
          objectPosition="50% 50%"
          alt="payjustnow-logo"
        />
        <InstalmentInfo>
          <TextBox>
            ZeroPay offers you 3 interest-free payments of{" "}
            <span>R&nbsp;{`${installmentAmount}`}</span>
          </TextBox>
          <TextBox>Choose ZeroPay during checkout.</TextBox>
        </InstalmentInfo>
      </WidgetBox>
    )
  }
})

const TextBox = styled.div``

const WidgetBox = styled.div`
  height: 75px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakXTiny}) {
    height: 100px;
  }
`
const InstalmentInfo = styled.div`
  text-align: center;
  flex: 1 1 auto;
  font-size: 12px;
  span {
    font-weight: bold;
  }
`

function parseAmount(value) {
  if (!value) {
    return 0
  }
  let amount = parseFloat(value).toFixed(2)

  if (isNaN(amount) || amount < 0) {
    return 0
  }
  return amount
}
function getInstallmentAmount(value) {
  value = +value
  if (isNaN(value) || value < 0) {
    return 0
  }
  let result = Math.floor((value * 100) / 3) / 100
  return endsWithZeroCents(result) ? result.toFixed(0) : result.toFixed(2)
}
function endsWithZeroCents(value) {
  let fixed = value.toFixed(2)
  let endsWith = fixed.lastIndexOf(".00") != -1
  return endsWith
}

export default ZeropayWidget

const ZEROPAY_WIDGET_QUERY = graphql`
  {
    zeropayImage: file(relativePath: { eq: "zeropay.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
