/* eslint-disable prettier/prettier */
import React, { useState, useContext, useEffect, useRef } from "react"
import StarRatings from "react-star-ratings"
import styled from "styled-components"
import Reaptcha from "reaptcha"
import AuthContext from "../../../context/AuthContext"
import { getUser } from "../../../services/auth"
import { Responsive, windowSizes } from "../../../utils/responsive"
import EllipsisLoader from "../../../zzz/organisms/ellipsisLoader"
import { postReview } from "../../../lib/woocommApi"

const ReviewPost = ({ product, defaultRating = 0 }) => {
  const { loggedInState, performLogout } = useContext(AuthContext)

  const captchaRef = useRef()

  // Initialise states
  const [selectedRating, setSelectedRating] = useState(0)
  const [captchaToken, setCaptchaToken] = useState(false)
  const [author, setAuthor] = useState("")
  const [email, setEmail] = useState("")
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const clearCaptchaToken = () => {
    setCaptchaToken(false)
  }

  useEffect(() => {
    if (defaultRating && defaultRating > 0) {
      setSelectedRating(defaultRating)
    }
  }, [defaultRating])

  // Clear all states
  const clearContent = () => {
    setAuthor("")
    setEmail("")
    setContent("")
    setSelectedRating(0)
  }

  // Hanlde review submission
  const handleSubmit = () => {
    // Field validation
    if (!author) {
      return setApiError("Please enter a valid name")
    }
    if (!loggedInState) {
      if (!email) {
        return setApiError("Please enter an email address")
      } else if (!/\S+@\S+\.\S+/.test(email)) {
        return setApiError("Please enter a valid email address")
      }
    }
    if (!content) {
      return setApiError(
        "Please type a short comment of your experience using the product"
      )
    }
    if (!selectedRating) {
      return setApiError("Please pick a star rating for the product")
    }

    if (!loggedInState && !captchaToken) {
      return setApiError(
        "Please complete the reCaptcha checkbox before continuing"
      )
    }

    if (author && content) {
      setApiError("")
      setSuccessMessage("")
      setLoading(true)

      const { token } = getUser()
      postReview(
        {
          id: product.wordpress_id,
          author,
          author_email: email,
          content,
          rating: selectedRating,
          captchaToken,
        },
        token,
        performLogout
      )
        .then((res) => {
          setLoading(false)
          clearContent()
          console.log("Review response: ", res)
          if (res && res.message) {
            setSuccessMessage(res.message)
          } else {
            setSuccessMessage(
              "Thank you for your review! Once it has been moderated, it will appear here"
            )
          }
        })
        .catch(async (err) => {
          setLoading(false)
          console.log("Request failed: ", err)
          if (err.message) setApiError(err.message)
          else
            setApiError(
              "Oh no, something went wrong while submitting your review. Our team has been notified and will look into it ASAP"
            )
          setLoading(false)
          captchaRef?.current?.reset?.()
        })
    }
  }

  // While loading, show loader
  if (loading) {
    return (
      <Grey>
        <EllipsisLoader />
      </Grey>
    )
  }

  return (
    <Grey>
      {successMessage && <div className="message-good">{successMessage}</div>}
      {!successMessage && (
        <>
          <h3>Leave us a review</h3>
          <p>Your email address will not be published, but your name will.</p>
          <div className="grid">
            <div className="left">
              <h4>
                Your Rating <span>*</span>
              </h4>
              <StarRatings
                rating={selectedRating}
                changeRating={(rat) => setSelectedRating(rat)}
                numberOfStars={5}
                name="rating"
                starDimension="30px"
                starSpacing="7px"
                starRatedColor="#F7A416"
                starEmptyColor="#CFD5DB"
                starHoverColor="#ffba42"
              />
              <h4>
                Comment <span>*</span>
              </h4>
              <textarea
                id="review_content"
                rows="5"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="right">
              {apiError && (
                <Responsive minWidth={windowSizes.mobile}>
                  <div className="message-bad">{apiError}</div>
                </Responsive>
              )}
              <h4>
                Name <span>*</span>
              </h4>
              <input
                id="review_author"
                type="text"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
              {loggedInState === false && (
                <>
                  <h4>
                    Email <span>*</span>
                  </h4>
                  <input
                    id="review_author_email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </>
              )}
              <CaptchaContainer>
                {!captchaToken && <p>Please check the box before proceeding</p>}
                <Reaptcha
                  ref={captchaRef}
                  sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                  onVerify={(responseToken) => {
                    setCaptchaToken(responseToken)
                  }}
                  onExpire={clearCaptchaToken}
                  onError={clearCaptchaToken}
                  size="normal"
                />
              </CaptchaContainer>
              <button onClick={handleSubmit}>Send Review</button>
              {apiError && (
                <Responsive maxWidth={windowSizes.mobile}>
                  <div className="message-bad">{apiError}</div>
                </Responsive>
              )}
            </div>
          </div>
        </>
      )}
    </Grey>
  )
}

export default ReviewPost

const Grey = styled.div`
  background-color: #f0f1f3;
  padding: 15px;

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
    }
  }

  .left {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-direction: column;
  }

  h3 {
    color: #223247;
    font-family: Roboto;
    font-size: 22px;
    font-weight: bold;
  }

  h4 {
    color: #223247;
    font-family: Roboto;
    font-size: 18px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 10px;
    span {
      color: #ff1f1f;
    }
  }

  .center {
    text-align: center;
  }

  textarea,
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #cfd5db;
    padding: 7px;
  }

  textarea {
    font-size: 16px;
    resize: none;
  }

  button {
    margin-top: 30px;
    border-radius: 3px;
    background-color: #223247;
    width: 100%;
    color: white;
    padding: 10px;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  button:disabled {
    background-color: #aaaaaa;
    cursor: not-allowed;
  }

  .message-good,
  .message-bad {
    display: flex;
    justify-content: flex-center;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
  }
  .message-bad {
    border: 1px solid #ff9696;
    background: #ffe2e2;
    color: #de1f1f;
  }
  .message-good {
    border: 1px solid #4fe322;
    background: #b9ffb8;
    color: #000000;
  }
`

const CaptchaContainer = styled.div`
  .g-recaptcha {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    max-width: 270px;
    .g-recaptcha {
      justify-content: flex-start;
    }
  }
`
