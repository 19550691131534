import React, { useEffect } from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronUp from "../../zzz/atoms/icons/files/chevron-up.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Accordian = ({ children }) => {
  // enable multiple items to be open at the same time
  let enableMulti = true

  const [active, setActive] = React.useState(0)
  // set first child state to active => accordian item is open
  const [activeItems, setActiveItems] = React.useState(
    children.reduce((a, c, i) => ({ ...a, [i]: c.props.defaultOpen }), {})
  )
  const validChildren = children.every(
    ({ type: { displayName } }) => displayName === "Accordian.Item"
  )
  if (!validChildren)
    throw new Error("Only use <Accordian.Item/> as children of <Accordian/>")

  const toggleHandler = (idx) => (itemRef) => {
    active === idx ? setActive(-1) : setActive(idx)
  }

  const multiToggleHandler = (idx) => () => {
    setActiveItems((prev) => ({ ...prev, [idx]: !prev[idx] }))
  }

  const isActive = (idx) => (enableMulti ? activeItems[idx] : active === idx)
  const toggle = (idx) =>
    enableMulti ? multiToggleHandler(idx) : toggleHandler(idx)

  return (
    <div>
      {children.map((item, idx) => ({
        ...item,
        props: {
          ...item.props,
          active: isActive(idx),
          toggleActive: toggle(idx),
        },
      }))}
    </div>
  )
}

const Item = ({ active, title, children, toggleActive, bright = false }) => {
  const [hue, shade] = active ? ["white", "000"] : ["white", "000"]
  const itemRef = React.useRef(null)
  const [renderActive, setRenderActive] = React.useState(() => active)
  const [animationActive, setAnimationActive] = React.useState(() => active)
  const focusOnTitle = () => itemRef.current.scrollIntoView()

  useEffect(() => {
    if (active) {
      // Mount the component, and then start the open animation
      setRenderActive(true)
      setTimeout(() => {
        setAnimationActive(true)
      }, 300)
    } else {
      // Start the hide animation, and then unmount the component
      setAnimationActive(false)
      setTimeout(() => {
        setRenderActive(false)
      }, 300)
    }
  }, [active])

  return (
    <>
      <Title
        className={`${active ? "accordian-is-open" : "accordian-is-closed"}`}
        ref={itemRef}
        active={active}
        onClick={() => toggleActive(itemRef)}
        bright={bright}
      >
        <p>{title}</p>
        <SvgIcon SvgComponent={ChevronUp} hue={hue} shade={shade} size="md" />
      </Title>
      {renderActive && (
        <ExpandAccordionStyling active={animationActive}>
          {children &&
            (children.length > 0
              ? children
                  .filter((i) => !!i)
                  .map((item, idx) => ({
                    ...item,
                    props: {
                      ...item.props,
                      active,
                      focusOnTitle,
                      toggleActive: () => toggleActive(itemRef),
                    },
                  }))
              : children)}
        </ExpandAccordionStyling>
      )}
    </>
  )
}

Item.displayName = "Accordian.Item"
Accordian.Item = Item

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Title = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  padding: 10px 20px;
  font-weight: 400;
  cursor: pointer;
  color: ${({ bright }) => (bright ? "#223247;" : "white;")};
  background-color: ${({ bright }) => (bright ? "#f0f0f0;" : "#223247;")};
  border-bottom: 1px solid #ffffff;
  > span.icon {
    transition: 0.3s all ease-in-out;
    border-radius: 3px;
    background-color: ${({ active }) => (active ? "#77848E" : "#ed1c24")};
    transform: ${({ active }) => (active ? "rotate(0deg)" : "rotate(180deg)")};
  }

  @media screen and (max-width: 600px) {
    span.icon {
      margin-left: 10px;
    }
  }
`

const ExpandAccordionStyling = styled.div`
  overflow: hidden;
  max-height: ${({ active }) => (active ? "3000px" : "0")};
  height: 100%;
  transition: all 0.3s ease-in-out;
`

export default Accordian
