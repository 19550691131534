import React from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ButtonLoader from "../../zzz/organisms/buttonLoader"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Button = ({
  onClick,
  label,
  className,
  loading = false,
  loaderSize = "32px",
  SvgComponent = null,
  color = "#ed1c24",
  disabled = false,
}) => {
  if (disabled) {
    color = "#9ba5ae"
  } else if (loading == "true") {
    color = "#b65457"
  }
  return (
    <ButtonContainer
      onClick={() => {
        !disabled && onClick()
      }}
      color={color}
      disabled={disabled}
      className={className}
    >
      {loading ? (
        <Text className="text">
          <ButtonLoader loaderSize={loaderSize} />
        </Text>
      ) : (
        <Text className="text">{label}</Text>
      )}
      {SvgComponent ? (
        <IconContainer loading={loading.toString()} color={color}>
          <SvgIcon
            SvgComponent={SvgComponent}
            hue="white"
            shade="000"
            size="lg"
          />
        </IconContainer>
      ) : null}
    </ButtonContainer>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const ButtonContainer = styled.button`
  background-color: ${(props) => props.color};
  color: #ffffff;
  border: none;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(3px);
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    justify-content: space-between;
    .text {
      margin: 0 auto;
    }
  }
`
const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  font-size: 20px;
  text-transform: uppercase;
`
const IconContainer = styled.div`
  background: ${(props) => props.color};
`
export default Button
