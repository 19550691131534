import React from "react"
import styled from "styled-components"
import IconColor100Nights from "../../zzz/atoms/icons/color/icon-color-100-night-trial"
import IconColorFastDelivery from "../../zzz/atoms/icons/color/icon-color-fast-delivery"
import IconColorSecureCheckout from "../../zzz/atoms/icons/color/icon-color-secure-checkout"
import IconColorGuarantee from "../../zzz/atoms/icons/color/icon-color-guantantee"
import IconColorReturns from "../../zzz/atoms/icons/color/icon-color-returns"
import Inner from "../../zzz/layout/pageInner/Inner"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const InfoIcons = ({ show100Day = false }) => {
  return (
    <Inner>
      <Container grid="repeat(4,1fr)" gridMd="repeat(2,1fr)" gridSm="1fr">
        <IconWrap key={1} as={Link} to={"/delivery/"}>
          <IconColorFastDelivery />
          <h5>Free delivery main city hubs</h5>
        </IconWrap>
        <IconWrap key={2} as={Link} to={"/cancellations-refunds/"}>
          <IconColorReturns />
          <h5>Easy Returns</h5>
        </IconWrap>
        <IconWrap key={3}>
          <IconColorSecureCheckout />
          <h5>Secure Checkout</h5>
        </IconWrap>
        {show100Day ? (
          <IconWrap key={4} as={Link} to={"/cancellations-refunds/"}>
            <IconColor100Nights />
            <h5>100 Day comfort trial</h5>
          </IconWrap>
        ) : (
          <IconWrap key={4}>
            <IconColorGuarantee />
            <h5>Lowest Price Guarantee</h5>
          </IconWrap>
        )}
      </Container>
    </Inner>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Container = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const IconWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  .icon-color {
    max-height: 80px;
    padding-bottom: 15px;
  }
  h5 {
    color: #223247;
    font-size: 2018;
    font-weight: bold;

    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 18px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    flex-direction: row;

    .icon-color {
      width: 103px;
      transform: scale(0.8);
      padding-bottom: 15px;
    }
  }
`

export default InfoIcons
