import React, { memo } from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronRight from "../../zzz/atoms/icons/files/chevron-right.jsx"
import { buildCrumbs } from "../../lib/util"
import Home from "../../zzz/atoms/icons/files/home.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
// const Crumbs = memo(({ product }) => {
const Crumbs = ({ product }) => {
  const crumbs = buildCrumbs(product)

  return (
    <Container>
      {/* Divan Start */}
      <Item>
        <SvgIcon
          className="home_icon"
          SvgComponent={Home}
          hue="tusk"
          shade="080"
          size="sm"
        />
        <Link to="/">Home</Link>
        {/* {crumbs.length > 0 && ( */}
        <SvgIcon SvgComponent={ChevronRight} hue="tusk" shade="100" />
        {/* )} */}
      </Item>
      {/* Divan End */}
      {crumbs.map(({ name, link }, i) => (
        <Item key={i}>
          <Link to={link}>{name}</Link>
          {/* {i < crumbs.length - 1 && ( */}
          <SvgIcon SvgComponent={ChevronRight} hue="tusk" shade="100" />
          {/* )} */}
        </Item>
      ))}
      {/* Divan Start */}
      <Item>
        <Text>{product.name}</Text>
      </Item>
      {/* Divan End */}
    </Container>
  )
}
// })

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #77848e;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 12px;
    line-height: 12px;
  }
  a {
    color: #77848e;
    transition: 0.2s all ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
    text-decoration: underline;
  }
  > span.icon {
    margin: 0 10px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
      &.home_icon {
        > span {
          width: 20px;
          height: 16px;
        }
      }
      > span {
        width: 12px;
        height: 13px;
        svg {
          transform: scale(0.6, 0.6);
        }
      }
    }
  }
`

export const Text = styled.p`
  font-size: 18px;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 12px;
    line-height: 12px;
  }
`

export default Crumbs
