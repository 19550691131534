import React from "react"

const IconExternalLink = ({ fill }) => (
  <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.71 0C11.42 0 12 .58 12 1.29v9.42c0 .71-.58 1.29-1.29 1.29H1.3C.58 12 0 11.42 0 10.71V1.3C0 .58.58 0 1.29 0h9.42zm-.16 10.71c.1 0 .16-.07.16-.16v-9.1a.16.16 0 00-.16-.16h-9.1a.16.16 0 00-.16.16v9.1c0 .1.07.16.16.16h9.1zM9.11 2.57c.17 0 .32.15.32.32v3.65c0 .28-.35.42-.55.22l-1.3-1.29-3.85 3.86a.32.32 0 01-.46 0l-.6-.6a.32.32 0 010-.46l3.86-3.86-1.3-1.29a.32.32 0 01.23-.55h3.65z"
      fill={fill}
    />
  </svg>
)

export default IconExternalLink
