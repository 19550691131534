import React from "react"

const IconColorSecureCheckout = () => (
  <div className="icon-color">
    <svg width="74" height="61" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M3.636 39.875c.42-9.475 6.786-14.502 19.098-15.079 12.311-.577 25.47-.88 39.474-.91v-3.972H1.97l1.666 19.961zM3.636 13.649c-.677-4.284 8.18-6.728 26.573-7.332 18.393-.603 29.06-.868 32-.793l-1.012-2.59-15.03-.92-40.06.92L1.97 6.317v7.834l1.666-.502zM48.316 42.167H71.95V58.51H48.316z"
          fillOpacity=".169"
          fill="#ED1C24"
        />
        <path
          d="M59.917 54.234c-.952 0-1.72-.784-1.72-1.755v-4.01c0-.971.768-1.755 1.72-1.755.951 0 1.718.784 1.718 1.755v4.01c0 .971-.767 1.755-1.718 1.755zm13.75-10.778v14.036c0 1.66-1.32 3.008-2.947 3.008H49.113c-1.627 0-2.946-1.347-2.946-3.008V43.456c0-1.66 1.32-3.008 2.946-3.008h1.964V37.44c0-4.982 3.978-9.036 8.864-9.023 4.886.012 8.815 4.123 8.815 9.11v2.921h1.964c1.627 0 2.947 1.347 2.947 3.008zm-19.643-3.008H65.81V37.44c0-3.315-2.646-6.015-5.893-6.015-3.248 0-5.893 2.7-5.893 6.015v3.008zm16.696 3.008H49.113v14.036H70.72V43.456z"
          fill="#ED1C24"
        />
        <path
          fill="#223247"
          d="M2.625 12.948v9.167h59.583v-9.167zM20.672 39.41v.93a1.4 1.4 0 01-1.397 1.397h-8.379A1.4 1.4 0 019.5 40.34v-.93a1.4 1.4 0 011.396-1.397h8.38a1.4 1.4 0 011.396 1.397zM43.016 39.41v.93a1.4 1.4 0 01-1.397 1.397H25.792a1.4 1.4 0 01-1.396-1.397v-.93a1.4 1.4 0 011.396-1.397H41.62a1.4 1.4 0 011.397 1.397z"
        />
        <path
          d="M64.5 26.797a18.01 18.01 0 00-3.303-1.136V8.573c0-2.336-1.317-4.23-4.232-4.23H7.868a4.231 4.231 0 00-4.232 4.23v35.104a4.231 4.231 0 004.232 4.23h36.007v3.426H5.904c-3.279 0-5.57-3.125-5.57-6.156V6.789C.333 3.753 3.42.917 6.65.917H59.145c2.49 0 5.355 2.682 5.355 5.872V26.797z"
          fill="#223247"
        />
      </g>
    </svg>
  </div>
)

export default IconColorSecureCheckout
