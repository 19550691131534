import React from "react"

const IconBadge = ({ fill }) => (
  <svg width="14" height="18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.925 9.574c-.617.624-.547.513-.77 1.359a1.99 1.99 0 01-.374.716l1.677 4.126a.562.562 0 01-.52.775l-1.875-.072-1.274 1.346a.56.56 0 01-.93-.174l-1.843-4.537a.983.983 0 00-.265-.044.982.982 0 00-.266.045L4.64 17.65a.56.56 0 01-.93.174l-1.274-1.346c-1.977.076-1.855.071-1.875.071a.562.562 0 01-.52-.774l1.678-4.127a1.99 1.99 0 01-.374-.716c-.223-.84-.152-.734-.77-1.357a2.029 2.029 0 01-.509-1.929c.225-.853.225-.721 0-1.571a2.029 2.029 0 01.509-1.929c.616-.624.546-.513.77-1.358a1.993 1.993 0 011.397-1.417c.827-.226.718-.16 1.326-.78.5-.508 1.24-.71 1.916-.522.822.225.702.228 1.529 0a1.973 1.973 0 011.917.52c.602.62.508.56 1.327.782.68.185 1.215.728 1.396 1.418.223.84.152.734.77 1.357.495.503.69 1.242.51 1.928-.225.853-.225.72 0 1.571a2.029 2.029 0 01-.51 1.93zm-8.997 6.842l1.19-2.926a1.951 1.951 0 01-1.265-.58c-.391-.398-.342-.354-1.11-.56-.029-.007-.053-.025-.08-.034l-1.25 3.076 1.49-.058 1.025 1.082zm8.16-1.025l-1.25-3.075c-.028.01-.052.026-.08.034-.765.201-.71.151-1.11.56-.35.356-.803.542-1.267.574l1.192 2.932 1.024-1.082 1.49.057zm-1.021-4.745c.284-1.085.267-1.062 1.055-1.86a.899.899 0 00.223-.855c-.287-1.069-.288-1.07 0-2.142a.898.898 0 00-.223-.855c-.79-.797-.772-.782-1.055-1.859a.867.867 0 00-.605-.619c-1.065-.289-1.05-.274-1.834-1.078a.844.844 0 00-.819-.224c-1.07.296-1.048.295-2.12 0a.84.84 0 00-.818.226c-.789.802-.75.78-1.832 1.076a.865.865 0 00-.606.618c-.284 1.085-.267 1.063-1.056 1.86a.899.899 0 00-.222.855c.288 1.071.286 1.071 0 2.143a.898.898 0 00.222.854c.792.798.773.782 1.056 1.859.08.302.311.54.606.62.838.225 1.005.234 1.616.856a.832.832 0 001.046.124 1.974 1.974 0 012.098-.001.828.828 0 001.046-.122c.618-.63.786-.637 1.616-.857a.867.867 0 00.606-.62zM6.8 10.1a3.3 3.3 0 110-6.6 3.3 3.3 0 010 6.6z"
      fill={fill}
    />
  </svg>
)

export default IconBadge
