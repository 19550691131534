import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Dimensions = ({ length, width, height }) => {
  return (
    <Container>
      <Dimension amount={length + " cm"} label="Length" />
      <Dimension amount="x" x />
      <Dimension amount={width + " cm"} label="Width" />
      <Dimension amount="x" x />
      <Dimension amount={height + " cm"} label="Height" />
    </Container>
  )
}

const Dimension = ({ amount, label, x }) => {
  return (
    <Wrap>
      <Amount x={x}>{amount}</Amount>
      {label && <Label>{label}</Label>}
    </Wrap>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Amount = styled.div`
  color: ${({ x }) => (x ? "#e8e8e8" : "#223247")};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Label = styled.div`
  color: #77848e;
  background-color: #e8e8e8;
  border-radius: 3px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrap = styled.div`
  display: grid;
  grid-template-rows: 30px 20px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 15px 1fr 15px 1fr;
  grid-gap: 10px;
`

export default Dimensions
