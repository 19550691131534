import React, { memo, useState } from "react"
import styled from "styled-components"
import FeverTreeWidget from "./FeverTreeWidget"
import FeverTreeSixMonthWidget from "./FeverTreeSixMonthWidget"
import PayflexWidget from "./PayflexWidget"
import PayjustnowWidget from "./PayjustnowWidget"
import ZeropayWidget from "./ZeropayWidget"
import SvgIcon from "../../../zzz/atoms/icons/svg-icon"
import ChevronUp from "../../../zzz/atoms/icons/files/chevron-up.jsx"
import ChevronDown from "../../../zzz/atoms/icons/files/chevron-down.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const CreditOptions = memo(
  ({ feverTree, feverTreeSixMonths, payflex, zeropay }) => {
    // Controls the state of the dropdown
    const [activeTab, setActiveTab] = useState(1)

    // Do payflex calculations to determine if we should display it
    const { amount = 0, max = 80000, min = 499 } = payflex
    const payflexAvailable = amount < max && amount >= min
    const payjustnowAvailable = amount < max && amount >= min

    const zeropayAvailable = amount > 0

    const dropdownOptions = []

    if (zeropayAvailable) {
      dropdownOptions.push({
        timeframe: "3 Months",
        widget: <ZeropayWidget {...zeropay} />,
      })
    }
    // if (payflexAvailable) {
    //   dropdownOptions.push({
    //     timeframe: "6 Weeks",
    //     widget: <PayflexWidget {...payflex} />,
    //   })
    // }
    // if (payjustnowAvailable) {
    //   dropdownOptions.push({
    //     timeframe: "3 Months",
    //     widget: <PayjustnowWidget />,
    //   })
    // }
    if (feverTreeSixMonths && feverTreeSixMonths.amount) {
      dropdownOptions.push({
        timeframe: "6 Months",
        widget: <FeverTreeSixMonthWidget {...feverTreeSixMonths} />,
      })
    }
    if (feverTree && feverTree.amount) {
      dropdownOptions.push({
        timeframe: "24 Months",
        widget: <FeverTreeWidget {...feverTree} />,
      })
    }

    if (feverTree && feverTree.amount && payflexAvailable) {
      return (
        <DropDown>
          <Label>Get it now, pay later!</Label>
          <ProductCreditOptionDropDown>
            {dropdownOptions.map((dr, idx) => (
              <CreditOptionButton
                key={idx}
                onClick={() => setActiveTab(idx)}
                active={idx == activeTab}
              >
                {dr.timeframe}
                <SvgIcon
                  SvgComponent={ChevronDown}
                  hue="midnight"
                  shade="100"
                  size="sm"
                />
              </CreditOptionButton>
            ))}
          </ProductCreditOptionDropDown>
          {dropdownOptions[activeTab].widget}
        </DropDown>
      )
    } else {
      return null
    }
  }
)

export default CreditOptions

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  padding: 10px;
`
const SubLabel = styled.div`
  font-size: 12px;
  font-weight: 200;
  align-items: center;
  span {
    font-weight: 400;
  }
`
const DashLine = styled.div`
  border-bottom: 1px dashed #dee3e8;
  margin: 5px 0 0 0;
`
const DropDown = styled.div`
  background: white;
  margin: 10px 0 0 0;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 3px;
`
const CreditOptionButton = styled.div`
  width: 100%;
  height: 50px;
  font-size: 14px;
  background-color: ${({ active }) => (active ? "white" : "#f2f2f2")};
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    width: 20px;
    height: 20px;
  }
`
const ProductCreditOptionDropDown = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`
