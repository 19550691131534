import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const PayflexWidget = memo(({ amount = 0 }) => {
  const {
    payflexImage: {
      childImageSharp: { gatsbyImageData: payflex },
    },
  } = useStaticQuery(PAYFLEX_WIDGET_QUERY)

  let parsedAmount = parseAmount(amount)
  let installmentAmount = getInstallmentAmount(parsedAmount)
  if (installmentAmount > 0) {
    return (
      <WidgetBox>
        <GatsbyImage
          image={payflex}
          style={{ width: "160px" }}
          imgStyle={{ objectFit: "contain" }}
          placeholder="none"
          objectFit="contain"
          objectPosition="50% 50%"
          alt="payflex-logo"
        />
        <InstalmentInfo>
          <TextBox>
            4 interest-free payments of{" "}
            <span> R&nbsp;{`${installmentAmount}`}</span> over 6 weeks.
          </TextBox>
          <TextBox>Choose Payflex during checkout</TextBox>
        </InstalmentInfo>
      </WidgetBox>
    )
  } else {
    return null
  }
})

const TextBox = styled.div``

const WidgetBox = styled.div`
  height: 75px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakXTiny}) {
    height: 100px;
  }
`
const InstalmentInfo = styled.div`
  text-align: center;
  flex: 1 1 auto;
  font-size: 12px;
  span {
    font-weight: bold;
  }
`

export default PayflexWidget

// These functions were copied directly from the Payflex script:
// https://widgets.payflex.co.za/your-merchant-name/partpay-widget-0.1.1.js?type=calculator&min=50&max=400&amount=300
function parseAmount(value) {
  if (!value) {
    return 0
  }
  let amount = parseFloat(value).toFixed(2)

  if (isNaN(amount) || amount < 0) {
    return 0
  }
  return amount
}
function getInstallmentAmount(value) {
  value = +value
  if (isNaN(value) || value < 0) {
    return 0
  }
  let result = Math.floor((value * 100) / 4) / 100
  return endsWithZeroCents(result) ? result.toFixed(0) : result.toFixed(2)
}
function endsWithZeroCents(value) {
  let fixed = value.toFixed(2)
  let endsWith = fixed.lastIndexOf(".00") != -1
  return endsWith
}

const PAYFLEX_WIDGET_QUERY = graphql`
  {
    payflexImage: file(relativePath: { eq: "payflex.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
