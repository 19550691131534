import React from "react"
import Button from "./Button"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import { navigate } from "gatsby"
import { ContentStyling } from "./AccordianImplementation"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ComfortLevel = ({ body, level }) => {
  return (
    <ContentStyling>
      <div>
        <h2 className="capitalize">{level}</h2>
        <br />
        <div dangerouslySetInnerHTML={{ __html: body }} />
        <br />
        <h4>Want to know how mattress Comfort levels are rated?</h4>
        <br />
        <Button
          SvgComponent={ArrowRight}
          label="Find Out"
          onClick={() => navigate("/how-is-mattress-firmness-measured/")}
        />
      </div>
    </ContentStyling>
  )
}

export default ComfortLevel
