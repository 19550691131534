/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import ProductSingle from "../components/ProductSingle"

const ProductPage = (props) => {
  const { pageContext, location } = props
  const thisProduct = props.data.wcProducts
  const brandVideo = props.data.brandVideo
  const seo = pageContext.seo
  const post_id = thisProduct.wordpress_id
  const bedWidths =
    (props.data.bedSizeTable && props.data.bedSizeTable.bed_sizes_acf.beds) ||
    []

  // Skip tracking because we perform tracking manually
  return (
    <Layout location={location} product={thisProduct} skipReSciTracking>
      <SEO
        title={thisProduct.name}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <ProductSingle
        location={location}
        product={thisProduct}
        brandVideo={brandVideo}
        bedWidths={bedWidths}
      />
    </Layout>
  )
}

export default ProductPage
export const PLACEHOLDER = graphql`
  query ($id: Int!) {
    bedSizeTable: localWpGraphQlPages(slug: { eq: "bed-sizes" }) {
      bed_sizes_acf {
        beds {
          name
          width
        }
      }
    }
    brandVideo: localWpGraphQlTmwGeneralSettings {
      product_videos {
        brandVideo {
          title
          videoUrl
          productCat
          description
          backgoundImage {
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
      }
    }
    wcProducts(wordpress_id: { eq: $id }) {
      wordpress_id
      name
      sku
      slug
      price
      regular_price
      fevertree_instalment
      description
      average_rating
      type
      shipping_class
      categories {
        slug
        name
        wordpress_parent_id
        wordpress_id
      }
      attributes {
        name
        options
      }
      acf {
        warrantee
        guarantee
        weight_capacity
        video
        comfort_trial_available
        couch_mode_dimensions {
          length
          width
          height
        }
        bed_mode_dimensions {
          length
          width
          height
        }
        sleeping_area_dimensions {
          length
          width
          height
        }
        bed_bundle_size_images {
          bundle_images {
            product_image
            product_image_sku
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        selected_bases {
          wordpress_id
          name
          images {
            src
            localFile {
              url
              childImageSharp {
                gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
              }
            }
          }
          product_variations {
            id
            sku
            price
            regular_price
            fevertree_instalment
            image {
              src
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
                }
              }
            }
            attributes {
              name
              option
            }
          }
        }
      }
      dimensions {
        height
        length
        width
      }
      images {
        src
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 500, layout: CONSTRAINED)
          }
        }
      }
      bundled_by {
        name
        slug
      }
      bundled_items {
        name
        wordpress_id
        sku
        slug
        shipping_class
        categories {
          slug
        }
        product_variations {
          attributes {
            name
            option
          }
          shipping_class
          sku
          price
          regular_price
          fevertree_instalment
          id
        }
        price
        regular_price
        fevertree_instalment
      }
      product_variations {
        id
        sku
        price
        regular_price
        fevertree_instalment
        manage_stock
        stock_status
        stock_quantity
        shipping_class
        attributes {
          name
          option
        }
        dimensions {
          length
          width
          height
        }
        image {
          src
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
      related_products {
        ...ProductCardInfo
      }
    }
  }
`
