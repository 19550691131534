import React from "react"
import styled from "styled-components"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import Size from "../../zzz/atoms/icons/files/size.jsx"
import MoonStar from "../../zzz/atoms/icons/files/moon-star.jsx"
import BadgeIcon from "../../zzz/atoms/icons/files/badge.jsx"
import Bed from "../../zzz/atoms/icons/files/bed.jsx"
import ShareIcon from "../../zzz/atoms/icons/files/external-link.jsx"
import Dimensions from "./Dimensions"
import Social from "../../zzz/organisms/blog/single/Social"
import { FormatSize } from "styled-icons/material-rounded"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Attributes = ({
  location,
  length,
  width,
  height,
  comfortLevel,
  warrantee,
  guarantee,
  weight,
}) => {
  return (
    <div>
      <ConditionalDetail
        condition={length && width && height}
        title="Dimensions"
        icon={Size}
      >
        <Dimensions length={length} width={width} height={height} />
      </ConditionalDetail>
      <ConditionalDetail
        condition={comfortLevel}
        title="Comfort Level"
        icon={MoonStar}
      >
        <Level>
          <Badge>{comfortLevel}</Badge>
        </Level>
      </ConditionalDetail>
      <ConditionalDetail
        condition={warrantee || guarantee}
        title="Warranty"
        icon={BadgeIcon}
      >
        {warrantee > 0 && <Text>{warrantee} Years Service Warranty</Text>}
        {guarantee > 0 && <Text>{guarantee} Year Guarantee</Text>}
      </ConditionalDetail>
      {weight && (
        <ConditionalDetail condition={true} title="Weight Limit" icon={Bed}>
          <Text>{weight} kg per person</Text>
        </ConditionalDetail>
      )}
      <Heading icon={ShareIcon} text={"Share This"} />
      <Social
        location={location}
        slug={location?.pathname}
        title={"Product"}
        gridIcons
        shareQueryString
        sharePath={`${location?.pathname}${location?.search}`}
      />
    </div>
  )
}

// ============================
// 	 🔧🧱 SUB COMPONENTS 🧱🔧
// ============================
const Heading = ({ icon, text }) => (
  <HeadingWrap>
    <SvgIcon SvgComponent={icon} hue="midnight" shade="040" /> {text}
  </HeadingWrap>
)

const ConditionalDetail = ({ condition, title, icon, children }) =>
  condition && (
    <>
      <Heading icon={icon} text={title} />
      {children}
    </>
  )

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const HeadingWrap = styled.div`
  border-bottom: 1px dashed #dee3e8;
  color: #223247;
  font-size: 20px;
  margin: 30px 0;
  margin-bottom: 10px;
`

const Text = styled.p`
  font-size: 16px;
`

const Badge = styled.div`
  background-color: #bfe7f6;
  border-radius: 2px;
  color: #2a9ac2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px;
  text-transform: capitalize;
`
const Level = styled.div`
  display: flex;

  span {
    font-size: 16px;
    font-weight: 400;
  }
`

export default Attributes
