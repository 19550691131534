import React, { memo } from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Container = memo(
  ({
    grey,
    grid,
    children,
    gap = "20px",
    toppad = "60px",
    bottompad = "60px",
    gridLg,
    gridMd,
    gridSm,
    gridXs,
  }) => {
    const gridProps = { grid, gap, gridLg, gridMd, gridSm, gridXs }
    return (
      <Outer grey={grey} toppad={toppad} bottompad={bottompad}>
        <Inner>
          {grid ? <Grid {...gridProps}>{children}</Grid> : children}
        </Inner>
      </Outer>
    )
  }
)

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Outer = styled.div`
  background: ${({ grey }) => (grey ? "#F8f8f8" : "white")};
  padding: ${({ toppad }) => toppad} 0 ${({ bottompad }) => bottompad} 0;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 30px 0;
  }
`

// allows 'grid-template-columns' property to be set for
// all screen sizes as props on component
const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ grid }) => grid};
  grid-gap: ${({ gap }) => gap};
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    ${({ gridLg }) => gridLg && "grid-template-columns:" + gridLg + ";"}
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    ${({ gridMd }) => gridMd && "grid-template-columns:" + gridMd + ";"}
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    ${({ gridSm }) => gridSm && "grid-template-columns:" + gridSm + ";"}
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    ${({ gridXs }) => gridXs && "grid-template-columns:" + gridXs + ";"}
  }
`
export { Container }
