import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { LinkWrapper as Link } from "../../../utils/linkWrapper"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const FeverTreeSixMonthWidget = memo(({ amount = 0 }) => {
  const {
    feverImage: {
      childImageSharp: { gatsbyImageData: fevertree },
    },
  } = useStaticQuery(FEVERTREE_WIDGET_QUERY)

  // If a simple "true" is passed, don't display a number
  let labeltext = "6 monthly payments"
  if (amount !== true) {
    let parsedAmount = parseAmount(amount)
    let installmentAmount = getInstallmentAmount(parsedAmount)
    labeltext = (
      <>
        6 interest-free monthly payments of{" "}
        <span>R&nbsp;{`${installmentAmount}`}</span>
      </>
    )
  }

  if (amount) {
    return (
      <WidgetBox>
        <GatsbyImage
          image={fevertree}
          style={{ maxWidth: "137px" }}
          imgStyle={{ maxWidth: "137px", objectFit: "contain" }}
          placeholder="none"
          objectFit="contain"
          objectPosition="50% 50%"
          alt="fevertree-logo"
        />
        <InstalmentInfo>
          <TextBox>{labeltext}</TextBox>
          <Link to={"/apply-for-credit/"}>
            Click <Blue>here</Blue> to apply for a credit account
          </Link>
        </InstalmentInfo>
      </WidgetBox>
    )
  } else {
    return null
  }
})

export default FeverTreeSixMonthWidget

function parseAmount(value) {
  if (!value) {
    return 0
  }
  let amount = parseFloat(value).toFixed(2)

  if (isNaN(amount) || amount < 0) {
    return 0
  }
  return amount
}
function getInstallmentAmount(value) {
  value = +value
  if (isNaN(value) || value < 0) {
    return 0
  }
  const adminFeePerMonth = 39
  const initiationFee = 89
  // Installment is: ( totalAmount + ((adminFeePerMonth x 6) + initiationFee) ) / 6 months
  let result = (value + (adminFeePerMonth * 6 + initiationFee)) / 6
  return endsWithZeroCents(result) ? result.toFixed(0) : result.toFixed(2)
}
function endsWithZeroCents(value) {
  let fixed = value.toFixed(2)
  let endsWith = fixed.lastIndexOf(".00") != -1
  return endsWith
}

const WidgetBox = styled.div`
  height: 75px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakXTiny}) {
    height: 100px;
  }
`
const Blue = styled.span`
  color: #0988bb;
  font-weight: bold;
`
const TextBox = styled.div``
const InstalmentInfo = styled.div`
  text-align: center;
  flex: 1 1 auto;
  font-size: 12px;
  span {
    font-weight: bold;
  }
`

const FEVERTREE_WIDGET_QUERY = graphql`
  {
    feverImage: file(relativePath: { eq: "fevertree.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
