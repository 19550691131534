import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import StarRatings from "react-star-ratings"

import { isInStoreFrontend } from "../../services/auth"
import { windowSizes } from "../../utils/responsive"
import { ifWindowLoaded } from "../../lib/util"

import BranchContext from "../../context/BranchContext"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Details = ({
  title,
  sku,
  oldPrice,
  salePrice,
  rating,
  mobile,
  setGoToRatingsValue,
  productStockFetched,
}) => {
  const { selectedBranch, selectedBranchObject, setBranchSelectPopupOpen } =
    useContext(BranchContext)
  const [price, setPrice] = useState(salePrice)
  const [blurState, setBlur] = useState(false)
  const blur = typeof window == "undefined" ? true : blurState
  const [windowWidth, setWindowWidth] = useState(0)

  // blur price on update (works as a transition)
  useEffect(() => {
    setBlur(true)
    setTimeout(() => setPrice(salePrice), 200)
    setTimeout(() => setBlur(false), 300)
  }, [salePrice])

  useEffect(() => {
    if (typeof window == "undefined" || typeof window.screen == "undefined") {
      return
    }
    const handleResize = () => {
      setWindowWidth(window.screen.width)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const formattedSavings = savings(oldPrice, salePrice)
  const formattedPercentage = percentage(oldPrice, salePrice)
  const isInStoreFrontendState = isInStoreFrontend()

  let renderH1 = true
  if (mobile && windowWidth > windowSizes.mobile) {
    renderH1 = false
  } else if (!mobile && windowWidth <= windowSizes.mobile) {
    renderH1 = false
  }

  return (
    <Container className={mobile && "mobile"}>
      <BranchInformationContainer>
        {!selectedBranchObject ? null : (
          <StyledBranchInformationHeading>{`Shopping at ${selectedBranchObject.branchName} Store`}</StyledBranchInformationHeading>
        )}
        <Button
          className={!selectedBranchObject && "max-size"}
          onClick={() => setBranchSelectPopupOpen(true)}
        >
          <div>{`${selectedBranch ? "Change" : "Select"} Store`}</div>
          <SvgIcon
            SvgComponent={ArrowRight}
            hue="danger"
            shade="fontColor"
            size="md"
          />
        </Button>
      </BranchInformationContainer>
      {!renderH1 ? null : <Name className={mobile && "mobile"}>{title}</Name>}
      {isInStoreFrontendState && <a>{sku}</a>}
      <Flex>
        <Price className={blur && "blur"}>
          {salePrice && <Sale>{ifWindowLoaded(format(price))}</Sale>}
          {!isNaN(oldPrice) && oldPrice && oldPrice != salePrice && (
            <Strikethrough>{ifWindowLoaded(format(oldPrice))}</Strikethrough>
          )}
        </Price>
        <Meta>
          <Ratings>
            <StarRatings
              rating={rating}
              starRatedColor="#F7A416"
              changeRating={(newRating) => setGoToRatingsValue(newRating)}
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starEmptyColor="#CFD5DB"
              starHoverColor="#ffba42"
            />
          </Ratings>
          {!isNaN(oldPrice) && oldPrice != salePrice && (
            <Horizontal>
              <Savings>You save {formattedSavings}</Savings>
              <DiscountLabel color={"teal"}>
                {formattedPercentage}% OFF!
              </DiscountLabel>
            </Horizontal>
          )}
        </Meta>
      </Flex>
      {oldPrice == salePrice && (
        <PriceSnippet>Our warehouse price to you!</PriceSnippet>
      )}
    </Container>
  )
}

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================
const format = (number) =>
  "R " +
  parseFloat(number)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")

const savings = (price, salePrice) => {
  return format(parseFloat(price) - parseFloat(salePrice))
}

const percentage = (price, salePrice) => {
  const saving = parseFloat(price) - parseFloat(salePrice)
  return parseInt((saving / parseFloat(price)) * 100)
}

const colors = {
  yellow: { background: "#FCDBA2", text: "#DA8D08;" },
  green: { background: "green", text: "white" },
  red: { background: "#efb0b3", text: "#d91a1f" },
  brightred: { background: "#d91a1f", text: "white" },
  teal: { background: "#0988bb", text: "white" },
}
const getColor =
  (type) =>
  ({ color }) =>
    colors[color][type]

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Name = styled.h1`
  font-size: 38px;
  line-height: 1.1;
  &:not(.mobile) {
    margin-top: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 10px;
  }
`
const Container = styled.div`
  &.mobile {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
    &.mobile {
      display: block;
      padding: 15px 0;
    }
  }
`
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

// ==================
//      PRICE
// =================
const PriceSnippet = styled.div`
  display: block;
  color: #0988bb;
  font-size: 18px;
`

const Price = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.1s all ease-in-out;
  &.blur {
    filter: blur(3px);
  }
`
const Sale = styled.h3`
  font-size: 25px;
  color: #ed1c24;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 22px;
  }
`
const Normal = styled.h3`
  font-size: 20px;
  color: #bac0c5;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 18px;
  }
`
const Strikethrough = styled(Normal)`
  text-decoration: line-through;
`
// ==================
//      META
// =================
const Meta = styled.div`
  /* direction: rtl; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`
const Ratings = styled.div`
  cursor: pointer;
  width: fit-content;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
`
const Horizontal = styled.div`
  display: flex;
`
const Savings = styled.div`
  color: ${({ color }) => (color ? color : "#0988bb")};
  font-size: 14px;
  padding: 0px 10px;
`

const DiscountLabel = styled.div`
  color: ${getColor("text")};
  padding: 0px 10px;
  background: ${getColor("background")};
  font-size: 13px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BranchInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: white;
  align-items: center;
  height: fit-content;
  margin-top: 50px;
  max-height: 50px;

  box-shadow: 0 5px 10px -10px black;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-top: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-bottom: 20px;
  }
`

const Button = styled.span`
  height: 35px;
  margin: 0;
  margin-left: auto;
  background: #fd3237;
  border-radius: 0 3px 3px 0;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  min-width: fit-content;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateX(2px);
    cursor: pointer;
  }

  > div {
    padding: 0;
    margin: 0 20px;

    @media (max-width: ${({ theme }) => theme.breakLarge}) {
      margin: 0 15px;
      font-size: 85%;
    }
  }

  > span {
    padding: 3px 1px;
    background: #da2e32;
  }

  &.max-size {
    width: 100%;
    border-radius: 3px;

    > div {
      margin: 0 auto;
    }
  }
`

const StyledBranchInformationHeading = styled.h3`
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  margin-right: 15px;
  margin-left: 12px;

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 14px;
    margin-right: 10px;
  }
`

export default Details
