import React from "react"

const IconSize = ({ fill }) => (
  <svg width="14" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.12 9.5c.49 0 .88.4.88.88v3.5c0 .48-.4.87-.88.87H.87a.88.88 0 01-.87-.88V1.63C0 1.14.4.75.87.75h3.5c.49 0 .88.4.88.88V9.5h7.87zM.87 1.63v11.63l3.5-3.5V8.63H2.84a.22.22 0 01-.22-.22v-.44c0-.12.1-.22.22-.22h1.53V6.44H2.84a.22.22 0 01-.22-.22v-.44c0-.12.1-.22.22-.22h1.53V4.25H2.84a.22.22 0 01-.22-.22V3.6c0-.12.1-.21.22-.21h1.53V1.63H.87zm12.25 12.25v-3.5h-1.75v1.53c0 .12-.1.22-.21.22h-.44a.22.22 0 01-.22-.22v-1.53H9.19v1.53c0 .12-.1.22-.22.22h-.44a.22.22 0 01-.22-.22v-1.53H7v1.53c0 .12-.1.22-.22.22h-.44a.22.22 0 01-.22-.22v-1.53H5l-3.5 3.5h11.63z"
      fill={fill}
    />
  </svg>
)

export default IconSize
