import React from "react"
import styled from "styled-components"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

/*
tableHeadeings is an array of headings
tableRows is an array of rows, where is row is an array of cells
*/
const Table = ({ tableHeadings = [], tableRows = [] }) => {
  return (
    <TableWrap>
      {tableHeadings.length && (
        <thead>
          <tr>
            {tableHeadings.map((heading, i) => (
              <th key={`heading-${i}`}>{heading}</th>
            ))}
          </tr>
        </thead>
      )}
      {tableRows.length && (
        <tbody>
          {tableRows.map((row, row_i) => {
            return (
              <tr key={`row-${row_i}`}>
                {row.map((cell, cell_i) => {
                  return <td key={`cell-${cell_i}`}>{cell}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      )}
    </TableWrap>
  )
}

export default Table

const TableWrap = styled.table`
  text-align: left;
  background: white;
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  overflow: hidden;
  min-width: 300px;
  width: 100%;
  font-size: 16px;
  tr:not(:last-child) {
    border-bottom: 1px dashed #dee3e8;
  }

  td {
    padding: 10px 20px;
  }

  th {
    background-color: #f0f1f3;
    color: #223247;
    padding: 10px 20px;
    font-weight: bold;
  }
  /*Mobile View*/
  @media only screen and (max-width: 760px) {
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      padding: 20px;
      > td:nth-of-type(1) {
        font-weight: bold;
      }
    }

    td {
      padding: 0;
      border: none;
      position: relative;
      text-align: left;
    }
  }
`
