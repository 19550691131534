import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Container } from "./Layout"
import Attributes from "./Attributes"
import AccordianImplementation from "./AccordianImplementation"
import StarRatings from "react-star-ratings"
import ReactPlayer from "react-player/youtube"
import { path } from "../../lib/util"
import ComfortLevel from "./ComfortLevel"
import Review from "./Review/Review"
import Table from "./Table"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductDetails = memo(
  ({
    location,
    product,
    productStatus,
    productRating,
    selected,
    goToRatingsValue,
    setGoToRatingsValue,
    sizeWidths,
  }) => {
    // Get comfort levels from graphql
    const graphqlQuery = useStaticQuery(GET_ACF_COMFORT_LEVELS)
    const comfortLevels =
      graphqlQuery.localWpGraphQlPages.acfComfortLevels.comfortLevels.reduce(
        (a, { title, description }) => ({ ...a, [title]: description }),
        {}
      )
    let comfortLevel = product.attributes.find(
      ({ name }) => name === "Comfort Level"
    )
    comfortLevel =
      comfortLevel && comfortLevel.options
        ? comfortLevel.options[0].toLowerCase()
        : ""

    // Get other product ACF info
    const warrantee = product.acf.warrantee
    const guarantee = product.acf.guarantee
    const weight = product.acf.weight_capacity

    const items = []
    if (product.description.length) {
      items.push({
        title: "Product Description",
        defaultOpen: true,
        content: product.description,
      })
    }

    if (comfortLevels[comfortLevel]) {
      items.push({
        title: "Comfort Level",
        defaultOpen: true,
        component: (
          <ComfortLevel
            level={comfortLevel}
            body={comfortLevels[comfortLevel]}
          />
        ),
      })
    }

    if (
      product.acf.couch_mode_dimensions &&
      product.acf.bed_mode_dimensions &&
      product.acf.sleeping_area_dimensions
    ) {
      var all_dimensions_titles = ["Position", "Length", "Width", "Height"]
      var all_dimensions = []

      var couch_data = product.acf.couch_mode_dimensions
      var bed_data = product.acf.bed_mode_dimensions
      var sleeping_data = product.acf.sleeping_area_dimensions

      var temp_couch = []
      temp_couch.push("Couch Position")
      temp_couch.push(couch_data.length)
      temp_couch.push(couch_data.width)
      temp_couch.push(couch_data.height)

      var temp_bed = []
      temp_bed.push("Bed Position")
      temp_bed.push(bed_data.length)
      temp_bed.push(bed_data.width)
      temp_bed.push(bed_data.height)

      var temp_sleeping = []
      temp_sleeping.push("Sleeping Area")
      temp_sleeping.push(sleeping_data.length)
      temp_sleeping.push(sleeping_data.width)
      temp_sleeping.push(sleeping_data.height)

      all_dimensions.push(temp_couch)
      all_dimensions.push(temp_bed)
      all_dimensions.push(temp_sleeping)

      if (all_dimensions.length !== 0) {
        items.push({
          title: "Sleeper Couch Dimensions",
          defaultOpen: true,
          component: (
            <Table
              tableHeadings={all_dimensions_titles}
              tableRows={all_dimensions}
            />
          ),
        })
      }
    }

    var is_bed_or_mattress = false
    product.categories.forEach((cat) => {
      if (cat.name == "Beds" || cat.name == "Mattresses") {
        is_bed_or_mattress = true
      }
    })
    if (is_bed_or_mattress && Object.keys(sizeWidths).length !== 0) {
      items.push({
        title:
          product.bundled_items && product.bundled_items.length
            ? "Bed Sizes"
            : "Mattress Sizes",
        defaultOpen: true,
        component: (
          <Table
            tableHeadings={["Size", "Dimensions"]}
            tableRows={sizeWidths}
          />
        ),
      })
    }

    if (product.acf.video) {
      items.push({
        title: "Product video",
        defaultOpen: true,
        component: (
          <ReactPlayer
            width="100%"
            height="450px"
            style={{ margin: "0 auto" }}
            url={product.acf.video}
          />
        ),
      })
    }

    items.push({
      title: "Reviews",
      defaultOpen: true,
      component: (
        <Review
          reviewData={productStatus}
          product={product}
          goToRatingsValue={goToRatingsValue}
        />
      ),
    })

    const length = path(["dimensions", "length"], selected)
    const width = path(["dimensions", "width"], selected)
    const height = path(["dimensions", "height"], selected)

    return (
      <Container grey grid="1fr 3fr" gap="40px" gridMd="1fr">
        <Left>
          <Label>About</Label>
          <Title>{product.name}</Title>
          <Ratings>
            <StarRatings
              rating={productRating}
              changeRating={(newRating) => setGoToRatingsValue(newRating)}
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starRatedColor="#F7A416"
              starEmptyColor="#CFD5DB"
              starHoverColor="#ffba42"
            />
          </Ratings>
          <Attributes
            location={location}
            length={length}
            width={width}
            height={height}
            comfortLevel={comfortLevel}
            warrantee={warrantee}
            guarantee={guarantee}
            weight={weight}
          />
        </Left>
        <Right>
          <Segment>
            <AccordianImplementation items={items} />
          </Segment>
        </Right>
      </Container>
    )
  }
)

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Segment = styled.div`
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 4px;
  overflow: hidden;
`

const Ratings = styled.div`
  cursor: pointer;
  width: fit-content;
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
`
const Label = styled.div`
  font-size: 14px;
  color: white;
  border-radius: 3px;
  padding: 5px 12px;
  background-color: #223247;
  width: fit-content;
  text-transform: uppercase;
`

const Title = styled.h4`
  color: #223247;
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
`
const Right = styled.div``

export default ProductDetails

const GET_ACF_COMFORT_LEVELS = graphql`
  {
    localWpGraphQlPages(pageId: { eq: 54158 }) {
      acfComfortLevels {
        comfortLevels {
          description
          title
        }
      }
    }
  }
`
